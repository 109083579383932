import {Component, OnDestroy, OnInit} from "@angular/core";
import {AdminService} from "../../../../core/services/admin.service";
import {Ticket} from "../../../domain/ticket.domain";
import {ChatMessagesDataservice} from "../../../data/chat-messages.dataservice";
import {SearchService} from "../../../services/search.service";
import {Subject} from "rxjs";
import {TicketService} from "../../../services/ticket.service";
import {Store} from "../../../store/store";
import {SidebarFilterStore} from "../../../store/sidebar-filter.store";
import {TicketData} from "../../../store/ticket.store";

@Component({
    selector: 'sidebar-support',
    templateUrl: './sidebar-support.component.html'
})
export class SidebarSupportComponent implements OnInit, OnDestroy {

    private ticketData: TicketData;
    private ngDestroyed: Subject<void> = new Subject<void>();

    constructor(private adminService: AdminService,
                private searchService: SearchService,
                private ticketService: TicketService,
                private chatMessagesDataservice: ChatMessagesDataservice,
                private _store: Store,
                private sidebarFilterStore: SidebarFilterStore,) {
    }

    get store() {
        return this._store;
    }

    ngOnInit(): void {
        this.store.ticketData.changed.takeUntil(this.ngDestroyed).subscribe(data => {
            this.ticketData = data;
        });
    }

    ngOnDestroy(): void {
        this.ngDestroyed.next();
        this.ngDestroyed.complete();
    }

    get ticket(): Ticket {
        return this.ticketData.ticket;
    }

    get username() {
        return this.adminService.username();
    }

    createTicket() {
        this.ticketService.openCreateTicket();
    }


    chooseDepartment(id, choose) {
        this.sidebarFilterStore.chooseDepartment(id, choose);
        this.searchService.search();
    }

    chooseStatus(id, choose) {
        this.sidebarFilterStore.chooseStatus(id, choose);
        this.searchService.search();
    }

    chooseAllOrOneDepartment(id, event = null) {
        if (event) {
            event.stopPropagation();
        }

        this.sidebarFilterStore.chooseAllOrOneDepartment(id);

        this.searchService.search();
    }

    chooseAllOrOneStatus(id, event = null) {
        if (event) {
            event.stopPropagation();
        }

        this.sidebarFilterStore.chooseAllOrOneStatus(id);

        this.searchService.search();
    }

    chooseSelfAssigned(event = null) {
        if (event) {
            event.stopPropagation();
        }

        this.sidebarFilterStore.chooseSelfAssigned();

        this.searchService.search();
    }

}
