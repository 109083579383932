<div class="sidebar">
  <nav class="sidebar-nav">
    <ul class="nav">
      <li class="nav-item">
        <div class="dropdown">
          <button class="btn btn-block btn-default dropdown-toggle" type="button"
                  id="profileDropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fa fa-user" aria-hidden="true"></i>
            &nbsp;&nbsp;{{username}}&nbsp;&nbsp;
          </button>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="profileDropdownMenuButton">
            <!--<a class="dropdown-item" href="#">-->
            <!--<i class="fa fa-cog" aria-hidden="true"></i>Настройки-->
            <!--</a>-->
            <a class="dropdown-item" routerLinkActive="active" [routerLink]="['/logout']">
              <i class="fa fa-sign-out" aria-hidden="true"></i> Выход
            </a>
          </div>
        </div>
      </li>
    </ul>

    <div class="newTicket" style="padding: 6px 15px">
      <button class="btn btn-secondary btn-block" (click)="createTicket();">Написать</button>
    </div>

    <div class="departments" *mobxAutorun="{ detach: true }">
      <button class="departmentSelect btn btn-block text-left rounded fast"
              [class.departmentSelected]="store.sidebarFilter.overrideDep == 0"
              [class.btn-primary]="store.sidebarFilter.overrideDep == 0"
              [class.btn-default]="store.sidebarFilter.overrideDep != 0"
              (click)="chooseAllOrOneDepartment(0)">
        <span class="icon-folder"></span>&nbsp;
        <span class="departmentName">Все отделы</span>
      </button>

      <button class="departmentSelect btn btn-block text-left rounded fast"
              *ngFor="let department of store.departments.active; let i = index"
              [class.departmentSelected]="store.sidebarFilter.isDepartmentSelected(department)"
              [class.btn-primary]="store.sidebarFilter.isDepartmentSelected(department)"
              [class.btn-default]="!store.sidebarFilter.isDepartmentSelected(department)"
              [class.disabled]="store.sidebarFilter.isOverridedDep"
              (click)="chooseDepartment(department.id, !store.sidebarFilter.isDepartmentSelected(department))">
        <span class="icon-folder departmentSelect__item"></span>&nbsp;
        <span class="departmentName">{{department.name}}</span>
        <span class="pointer only-one"
              [class.only-one-select]="department.id == store.sidebarFilter.overrideDep"
              (click)="chooseAllOrOneDepartment(department.id, $event)">только</span>
      </button>
    </div>
    
    <div class="assigned" *mobxAutorun="{ detach: true }">
      <button class="selfAssignedSelect btn btn-block text-left rounded fast"
              [class.statusSelected]="store.sidebarFilter.selfAssigned"
              [class.btn-success]="store.sidebarFilter.selfAssigned"
              [class.btn-default]="!store.sidebarFilter.selfAssigned"
              (click)="chooseSelfAssigned()">
        <span>+ Мои</span>
        <i class="fa fa-question-circle pull-right"
           title="Показать дополнительные тикеты, назначенные на вас из всех отделов, с учетом выбранных статусов"
           data-toggle="tooltip"
           data-placement="right"></i>
      </button>
    </div>

    <div class="statuses" *mobxAutorun="{ detach: true }">
      <button class="statusSelect btn btn-block text-left rounded fast"
              [class.statusSelected]="store.sidebarFilter.overrideStat == 0"
              [class.btn-success]="store.sidebarFilter.overrideStat == 0"
              [class.btn-default]="store.sidebarFilter.overrideStat != 0"
              (click)="chooseAllOrOneStatus(0)">
        <span class="icon-tag"></span>&nbsp;
        <span class="statusName">Все статусы</span>
      </button>

      <button class="statusSelect btn btn-block text-left rounded fast"
              *ngFor="let status of store.statuses.list; let i = index"
              [class.statusSelected]="store.sidebarFilter.isStatusSelected(status)"
              [class.btn-success]="store.sidebarFilter.isStatusSelected(status)"
              [class.btn-default]="!store.sidebarFilter.isStatusSelected(status)"
              [class.disabled]="store.sidebarFilter.isOverridedStat"
              (click)="chooseStatus(status.id, !store.sidebarFilter.isStatusSelected(status))">
        <span class="pointer only-one"
              [class.only-one-select]="status.id == store.sidebarFilter.overrideStat"
              (click)="chooseAllOrOneStatus(status.id, $event)">только</span>
        <span *ngIf="store.tickets.counters[''+status.id] > 0"
              class="badge badge-danger pull-right anti-only-one">
              {{store.tickets.counters[""+status.id]}}
        </span>
        <span class="icon-tag statusSelect__item"></span>&nbsp;
        <span class="statusName">{{status.name}}</span>
      </button>
    </div>

    <br/>
    <a href="/info/version" target="_blank" *mobxAutorun="{ detach: true }">
      <div style="text-align: center;color: gray;font-size: small">
        <div>
          {{store.systemInfo?.gitInfo?.branch}} ({{store.systemInfo?.gitInfo?.revision?.substr(0, 7) + "..."}})
        </div>
      </div>
    </a>
  </nav>
</div>
