import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {TicketService} from "../../services/ticket.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Ticket} from "../../domain/ticket.domain";
import {TicketListDataservice} from "../../data/ticket-list.dataservice";
import {Subject} from "rxjs";
import "rxjs/add/operator/first";
import {SearchService} from "../../services/search.service";
import {GrowlMessageService} from "../../../core/services/growl-message.service";
import {LoginDataservice, UserInfo} from "../../../core/data/login.dataservice";
import {PanelTicketIds, TicketDataservice} from "../../data/ticket.dataservice";
import {Admin} from "../../domain/admin.domain";
import {Department} from "../../domain/department.domain";
import {Status} from "../../domain/status.domain";
import {Location} from "@angular/common";
import {NavigationService} from "../../services/navigation.service";
import {Base64} from "js-base64";
import {Tag} from "../../domain/tag.domain";
import {Store} from "../../store/store";
import "lodash";
import {AdminService} from "../../../core/services/admin.service";
import {TicketList} from "../../store/ticket-list.store";
import {Table} from "primeng/table";

declare var _: any;
declare var $: any;
declare var require: any;

@Component({
    templateUrl: 'dashboard.component.html'
})
export class DashboardComponent implements OnInit, OnDestroy {

    @ViewChild('dt') dt: Table;

    private _userInfo: UserInfo;
    private ticketList: TicketList = {loading: true, tickets: []};
    private ngDestroyed: Subject<void> = new Subject<void>();

    private _dtFirst: number = 0;
    private _dtRows: number = 20;
    private _dtOrder: number = -1;

    extendedSearch: boolean;

    constructor(private ticketService: TicketService,
                private loginDataservice: LoginDataservice,
                private ticketListDataservice: TicketListDataservice,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private navigationService: NavigationService,
                private ticketDataService: TicketDataservice,
                private growlMessageService: GrowlMessageService,
                private location: Location,
                private adminService: AdminService,
                private searchService: SearchService,
                private store: Store) {
    }

    get currentLoggedInAdmin(): Admin {
        return this.adminService.currentLoggedInAdmin
    }

    bindShowPagedata() {
        this.showPagedata();
        this.dt.onPage.subscribe((event) => {
            this._dtRows = event.rows;
            this._dtFirst = event.first;
            this.showPagedata();
        });
        this.dt.onSort.subscribe((event) => {
            this._dtOrder = event.order;
            this.showPagedata();
        });
    }

    ngOnInit() {
        let q = "";

        this.navigationService.navStart.takeUntil(this.ngDestroyed).subscribe((nd) => {
            if (this.ticketList != null) {
                this.ticketList.loading = true;
            }
        });

        // восстановление прокрутки
        let st = 0, sl = 0;

        this.store.tickets.changed.takeUntil(this.ngDestroyed).subscribe(data => {
            if (_.isEqual(this.ticketList, data)) {
                return;
            }

            if (!this.ticketList.loading) {
                st = $(".content-main").scrollTop();
                sl = $(".content-main").scrollLeft();
            }

            this.ticketList = data;

            if (!this.ticketList.loading) {
                data.loading = true; // скрываем данные
                let __this = this;
                let delayed = function () {
                    if (!__this.dt) {
                        setTimeout(() => {
                            delayed();
                        }, 100);
                        return;
                    }

                    // пейджим, когда все прогрузилось
                    // именно в таком порядке, иначе страница сбрасывается
                    __this.dt.sortOrder = __this.searchService.sortOrder = __this._dtOrder;
                    __this.dt.rows = __this._dtRows;
                    __this.dt.first = __this._dtFirst < __this.ticketList.tickets.length ? __this._dtFirst : 0;
                    // __this.dt.paginate();

                    // показываем данные
                    __this.ticketList.loading = false;

                    // подменяем обработчик сортировки на свой
                    if (!(__this.dt as any).__sort) {
                        (__this.dt as any).__sort = __this.dt.sort;
                        __this.dt.sort = (event) => {
                            (__this.dt as any).__sort(event);
                            if (__this.ticketList.count > __this.ticketList.tickets.length) {
                                __this.ticketList.loading = true;
                                __this.searchService.search();
                            }
                        };
                    }

                    // при изменениях в пейджинге - сохраняем в урл
                    __this.bindShowPagedata();

                    // выравниваем скроллы
                    setTimeout(() => {
                        $(".content-main").scrollTop(st);
                        $(".content-main").scrollLeft(sl);
                        __this.onResize();
                    }, 1)
                };
                setTimeout(() => {
                    delayed();
                }, 1);
            }
        });
        this.loginDataservice.userInfo.takeUntil(this.ngDestroyed).subscribe(data => {
            this._userInfo = data;

            // выполняем поиск после загрузки информации об админе
            this.activatedRoute.queryParams.takeUntil(this.ngDestroyed).subscribe((params) => {
                this.applyPagedata(window.localStorage.getItem("queryParamsStr"));
                this.applyPagedata(params['q'] ? Base64.decode(params['q']) : "");
            });
        });
        this.searchService.extendedSearch.asObservable().takeUntil(this.ngDestroyed).subscribe(data => {
            this.extendedSearch = data;

            $(".content-main").scrollTop(0);
            $(".content-main").scrollLeft(0);
            setTimeout(() => this.onResize(), 1);
            setTimeout(() => this.onResize(), 1); // в первый раз иногда некорректно рассчитывается высота документа
        });

        this.onResize();
    }

    ngOnDestroy(): void {
        this.ngDestroyed.next();
        this.ngDestroyed.complete();
    }

    get userInfo() {
        return this._userInfo
    }

    get msgs() {
        return this.growlMessageService.msgs
    }

    removeGrowlMsg(event) {
        this.growlMessageService.removeMsg(event.message);
    }

    showPagedata() {
        let departmentsSelection = this.store.departments.active.map((d: Department) => {
            return (this.store.sidebarFilter.departmentsState.has("" + d.id) ? 1 : 0) << d.id
        }).reduce((a, b) => a + b, 0);
        let statusesSelection = this.store.statuses.list.map((s: Status) => {
            return (this.store.sidebarFilter.statusesState.has("" + s.id) ? 1 : 0) << s.id
        }).reduce((a, b) => a + b, 0);
        let query = this.searchService.searchModel;
        let paginationFirst = this._dtFirst;
        let paginationRows = this._dtRows;

        let overrideDep = this.store.sidebarFilter.overrideDep;
        let overrideStat = this.store.sidebarFilter.overrideStat;
        let sortOrder = this.searchService.sortOrder;
        let selfAssigned = this.store.sidebarFilter.selfAssigned;

        let map = JSON.stringify({
            f1: [departmentsSelection, statusesSelection, overrideDep, overrideStat, selfAssigned],
            q1: query,
            p1: [paginationFirst, paginationRows, sortOrder],
        });

        window.localStorage.setItem("queryParamsStr", map);

        let url = this.router.serializeUrl(this.router.createUrlTree(['/support/dashboard'], {queryParams: {q: Base64.encode(map)}}));
        this.navigationService.replaceUrl(url);
    }

    applyPagedata(str: string) {
        console.log("str: " + str);
        try {
            let map = str ? JSON.parse(str) : {};
            if (!map) {
                return;
            }
            if (map.f1) {
                if (map.f1[0] != null) {
                    this.store.departments.active.forEach((d: Department) => {
                        let selected = (((map.f1[0] >> d.id) & 1) == 1);
                        this.store.sidebarFilter.chooseDepartment(d.id, selected);
                    });
                }
                if (map.f1[1] != null) {
                    this.store.statuses.list.forEach((s: Status) => {
                        let selected = (((map.f1[1] >> s.id) & 1) == 1);
                        this.store.sidebarFilter.chooseStatus(s.id, selected);
                    });
                }
                this.store.sidebarFilter.overrideDep = map.f1[2];
                this.store.sidebarFilter.overrideStat = map.f1[3];
                this.store.sidebarFilter.selfAssigned = map.f1[4];
            }
            if (map.p1) {
                this._dtFirst = map.p1[0] || 0;
                this._dtRows = map.p1[1] || 20;
                this.searchService.sortOrder = this._dtOrder = map.p1[2] || -1;
            }
            if (map.q1) {
                this.searchService.initSearch(map.q1);
            } else {
                this.searchService.initSearch();
            }
        } catch (e) {
            console.log(e);
        }
    }

    // Данные для отображения

    get statuses() {
        return this.store.statuses.list;
    }

    get departments() {
        return this.store.departments.active;
    }

    get tickets() {
        return this.ticketList.tickets;
    }

    get count() {
        return this.ticketList.count;
    }

    get loading() {
        return this.ticketList.loading;
    }

    // Обработка нажатий

    selectedTickets: Ticket[] = [];

    ticketClick($event) {
        var target = $event.target;
        let el = null;
        if ($(target).closest('td').is('.ticketField__check')) {
            // курсор попал мимо галки
            el = $(target).find(".ui-chkbox-box")[0];
        } else if ($(target).closest('td').is('.ticketField__link')) {
            // переход на чат
            el = $(target).find("a")[0];
        }
        if (el) {
            el.click();
        }
    }

    onSort(e) {
        this.searchService.setOrder(e.field, e.order)
    }

    // Форматирование

    isTicketOld(date: Date): boolean {
        return (new Date().getTime() - date.getTime() > 1000 * 60 * 60 * 2);
    }

    rowStyleClass(rowData: any) {
        if (rowData.updating) {
            return 'ticket__updating';
        }
        if (new Date().getTime() - rowData.activity.getTime() > 1000 * 60 * 60 * 24) {
            return 'ticket__alert';
        }
        return '';
    }

    focusEditable(comp) {
        comp.show();
        comp.bindDocumentClickListener();
    }

    // Управление отображением таблицы

    smallScreen: boolean;

    onResize($event?): void {
        let mq1: MediaQueryList = window.matchMedia('screen and (max-width:991px)');
        this.smallScreen = mq1.matches;

        let wh = $(window).height();
        if ($(".ticketsTable .ui-table").is(":visible")) {
            let st = $(".content-main").scrollTop();
            let sl = $(".content-main").scrollLeft();
            $(".content-main").scrollTop(0);
            $(".content-main").scrollLeft(0);

            $(".ticketsTable").css("height", "auto");

            let dh = $(document).height();
            let oh = $(".ticketsTable").outerHeight();
            if (dh > wh) {
                let h = oh - (dh - wh);
                $(".ticketsTable").css("height", h + "px");
                $(".content-main").css("overflow-x", "scroll");
                $(".content-main").css("overflow-y", "scroll");
            } else {
                $(".content-main").css("overflow-x", "visible");
                $(".content-main").css("overflow-y", "visible");
            }
            $(".content-main").scrollTop(st);
            $(".content-main").scrollLeft(sl);
        }
    }

    // Редактирование тикета из таблицы

    changeDepartment(ticket: Ticket, dept: Department) {
        ticket.department = dept;
        ticket.updating = true;
        this.ticketDataService.changeDepartment(ticket.panelPrefix, ticket.id, dept.id, ticket.version);
    }

    changeStatus(ticket: Ticket, status: Status) {
        ticket.status = status;
        ticket.updating = true;
        this.ticketDataService.changeStatus(ticket.panelPrefix, ticket.id, status.id, ticket.version);
    }

    changeMassStatus(selectedTickets: Ticket[], status: Status) {
        selectedTickets.forEach(value => {
            value.status = status;
            value.updating = true;
        })

        const groupedTickets = selectedTickets.reduce((acc, ticket) => {
            if (!acc[ticket.panelPrefix]) {
                acc[ticket.panelPrefix] = [];
            }
            acc[ticket.panelPrefix].push(ticket.id);
            return acc;
        }, {} as Record<string, number[]>);

        const ticketsData: PanelTicketIds[] = Object.keys(groupedTickets).map(panelPrefix => {
            return { panelPrefix, ids: groupedTickets[panelPrefix] };
        });

        this.ticketDataService.changeMassStatus(ticketsData, status.id);
    }

    isProcessChangeMassStatus() {
        return this.ticketDataService.isProcessChangeMassStatus();
    }

    assignToMe(ticket: Ticket) {
        if (ticket.assignedTo != null) {
            return
        }
        this.changeAssignTo(ticket, this.adminService.currentLoggedInAdmin, true);
    }

    changeAssignTo(ticket: Ticket, admin: Admin, followRedirect: boolean = false) {
        ticket.assignedTo = admin;
        ticket.updating = true;
        this.ticketDataService.assignTo(ticket.panelPrefix, ticket.id, admin.username, ticket.version, followRedirect);
    }

    changeTag(ticket: Ticket, tag: Tag) {
        ticket.tag = tag;
        ticket.updating = true;
        this.ticketDataService.changeTag(ticket.panelPrefix, ticket.id, tag.id, ticket.version)
    }

    ticketEdit($event, rowData) {
        // console.log(rowData);
    }

    blurEditable(self: any) {
        // принудительное снятие фокуса с элемента ввода
        setTimeout(() => {
            // workaround отсюда https://github.com/primefaces/primeng/issues/5352
            // TODO переделать на что-то нормальное
            let escape = {
                key: '27',
                'keyCode': '27' // deprecated, но используется в primeng
            };
            let $td = $(self.el.nativeElement).closest("td");
            if ($td.is(".ui-editing-cell")) {
                $td[0].dispatchEvent(new KeyboardEvent("keydown", escape));
            }
        }, 1);
    }

    getAvailableAdmins(ticket: Ticket): Admin[] {
        return this._userInfo.adminsInPanels[ticket.panelPrefix]
    }

    getTags(ticket: Ticket): Tag[] {
        return this._userInfo.tagsInPanels[ticket.panelPrefix]
    }

    openChatMassReply(selectedTickets: Ticket[]) {
        let ticketIdsGroupedByPanel: { [k: string]: number[]; } = selectedTickets.reduce(function(groups, ticket) {
            const panel = ticket.panelPrefix;
            groups[panel] = groups[panel] || [];
            groups[panel].push(ticket.id);
            return groups;
        }, {});

        let ticketIdsStrGroupedByPanel: { [k: string]: string; } = {};
        Object.getOwnPropertyNames(ticketIdsGroupedByPanel).forEach(prop => {
            ticketIdsStrGroupedByPanel[prop] = ticketIdsGroupedByPanel[prop].sort().join(",");
        });

        this.router.navigate(['/support/chatMassReply'], {queryParams: ticketIdsStrGroupedByPanel});
        return false
    }
}
