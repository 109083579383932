import { OnInit } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { SearchQueryParserService } from "../../shared/search-query-parser.service";
import "rxjs/add/operator/debounceTime";
import "rxjs/add/operator/map";
import { TicketListDataservice } from "../data/ticket-list.dataservice";
import { AppSettings } from "../../../app-settings";
import { Store } from "../store/store";
import { StatusesStore } from "../store/statuses.store";
import { LoginDataservice } from '../../core/data/login.dataservice';
var SearchService = /** @class */ (function () {
    function SearchService(loginDataservice, ticketListDataservice, store) {
        var _this = this;
        this.ticketListDataservice = ticketListDataservice;
        this.store = store;
        this.defaultSearchModel = '';
        this._searchModel = '';
        this._search = new BehaviorSubject("");
        this.extendedSearch = new BehaviorSubject(false);
        this.departmentKey = "отдел";
        this.statusKey = "статус";
        this.initialized = false;
        this._timer = AppSettings.COUNTDOWN_TIME;
        this._keywords = [
            '№',
            'от',
            'до',
            'дата',
            'тема',
            'клиент',
            'статус',
            'отдел',
            'панель',
            'назначен',
            'урл',
            'заказ',
        ];
        this.sortField = "byactivity";
        this.sortOrder = -1;
        loginDataservice.userInfo.filter(function (userInfo) { return !userInfo.loading; }).subscribe(function (userInfo) {
            _this._userInfo = userInfo;
        });
        this.searchEvents = this._search.asObservable();
        setInterval(function () {
            if (_this._timer == 0) {
                _this.search(_this.searchModel, false);
                return;
            }
            _this._timer--;
        }, 1000);
    }
    SearchService.prototype.ngOnInit = function () {
    };
    SearchService.prototype.initSearch = function (initial) {
        // инициируем первый поиск
        this.clearSearch(initial);
        this._timer = AppSettings.COUNTDOWN_TIME;
    };
    SearchService.prototype.clearSearch = function (initial) {
        this._searchModel = initial ? initial : this.defaultSearchModel;
        this.search();
    };
    SearchService.prototype.appendSearch = function (item) {
        // просто добавить строку в конец
        if (!this._searchModel.endsWith(" ")) {
            this._searchModel += " ";
        }
        this._searchModel += item.str + " ";
        this.search();
    };
    Object.defineProperty(SearchService.prototype, "keywords", {
        get: function () {
            return this._keywords;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SearchService.prototype, "searchModel", {
        get: function () {
            return this._searchModel;
        },
        set: function (value) {
            this._searchModel = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SearchService.prototype, "timer", {
        get: function () {
            return this._timer;
        },
        enumerable: true,
        configurable: true
    });
    SearchService.prototype.search = function (str, erase) {
        if (str === void 0) { str = this.searchModel; }
        if (erase === void 0) { erase = true; }
        var urlRegex = new RegExp('^((http|https)://|(//))[a-z0-9]', 'i');
        if (urlRegex.test(str)) {
            str = str.replace(/[^a-zA-Zа-яА-ЯёЁ0-9]+ */g, ' ');
        }
        this.doSearch(this._searchModel = str, erase);
        this._timer = AppSettings.COUNTDOWN_TIME;
    };
    SearchService.prototype.parseQuery = function (searchStr) {
        return SearchQueryParserService.parse(searchStr, { keywords: this.keywords, ranges: [] });
    };
    SearchService.prototype.makeQuery = function (map) {
        var result = "";
        for (var _i = 0, _a = this.keywords; _i < _a.length; _i++) {
            var kw = _a[_i];
            if (map[kw]) {
                if (map[kw] instanceof Array && map[kw].length > 0) {
                    result += kw + ":" + map[kw].join(",") + " ";
                }
                if (typeof map[kw] === 'string' && map[kw] != "") {
                    result += kw + ":" + map[kw] + " ";
                }
            }
        }
        if (map["текст"]) {
            result += map["текст"] + " ";
        }
        return result;
    };
    SearchService.prototype.doSearch = function (searchStr, erase) {
        if (erase === void 0) { erase = true; }
        var _a;
        var str = searchStr.trim() ? searchStr : "";
        var selectedDepartments = this.store.sidebarFilter.selectedDepartments;
        var departmentsFilter = 'отдел:' + selectedDepartments.map(function (d) { return d.id; }).join(',');
        var selected = 'статус:' + this.store.sidebarFilter.selectedStatuses.map(function (s) { return s.id; }).join(',') + ' ';
        if (this._userInfo && this.store.sidebarFilter.selfAssigned) {
            selected += '(назначен:' + this._userInfo.user.username;
            if (selectedDepartments.length > 0) {
                selected += ' OR ' + departmentsFilter;
            }
            selected += ')';
        }
        else {
            selected += departmentsFilter;
        }
        selected += ' ';
        var countInProgress = departmentsFilter + " " +
            "статус:" + StatusesStore.STATUS_IN_PROGRESS.id;
        var parsed = this.parseQuery(searchStr);
        var sideFilters = parsed["№"].length === 0 && parsed["клиент"].length === 0 && !parsed["text"];
        var searchMap = {
            query: sideFilters ? selected + str : str,
            counters: (_a = {}, _a["" + StatusesStore.STATUS_IN_PROGRESS.id] = countInProgress, _a)
        };
        searchMap["sort"] = { field: this.sortField, order: this.sortOrder };
        this.ticketListDataservice.loadTicketListSubject.next({ searchMap: searchMap, erase: erase });
        this._search.next(str);
    };
    SearchService.prototype.setOrder = function (sortField, sortOrder) {
        if (this.sortOrder != sortOrder || this.sortField != sortField) {
            this.sortField = "by" + sortField;
            this.sortOrder = sortOrder;
            // let __this = this;
            // setTimeout(function () {
            //     __this.search();
            // }, 100);
        }
    };
    return SearchService;
}());
export { SearchService };
