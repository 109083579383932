import { OnDestroy, OnInit } from "@angular/core";
import { AdminService } from "../../../../core/services/admin.service";
import { ChatMessagesDataservice } from "../../../data/chat-messages.dataservice";
import { SearchService } from "../../../services/search.service";
import { Subject } from "rxjs";
import { TicketService } from "../../../services/ticket.service";
import { Store } from "../../../store/store";
import { SidebarFilterStore } from "../../../store/sidebar-filter.store";
var SidebarSupportComponent = /** @class */ (function () {
    function SidebarSupportComponent(adminService, searchService, ticketService, chatMessagesDataservice, _store, sidebarFilterStore) {
        this.adminService = adminService;
        this.searchService = searchService;
        this.ticketService = ticketService;
        this.chatMessagesDataservice = chatMessagesDataservice;
        this._store = _store;
        this.sidebarFilterStore = sidebarFilterStore;
        this.ngDestroyed = new Subject();
    }
    Object.defineProperty(SidebarSupportComponent.prototype, "store", {
        get: function () {
            return this._store;
        },
        enumerable: true,
        configurable: true
    });
    SidebarSupportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.store.ticketData.changed.takeUntil(this.ngDestroyed).subscribe(function (data) {
            _this.ticketData = data;
        });
    };
    SidebarSupportComponent.prototype.ngOnDestroy = function () {
        this.ngDestroyed.next();
        this.ngDestroyed.complete();
    };
    Object.defineProperty(SidebarSupportComponent.prototype, "ticket", {
        get: function () {
            return this.ticketData.ticket;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SidebarSupportComponent.prototype, "username", {
        get: function () {
            return this.adminService.username();
        },
        enumerable: true,
        configurable: true
    });
    SidebarSupportComponent.prototype.createTicket = function () {
        this.ticketService.openCreateTicket();
    };
    SidebarSupportComponent.prototype.chooseDepartment = function (id, choose) {
        this.sidebarFilterStore.chooseDepartment(id, choose);
        this.searchService.search();
    };
    SidebarSupportComponent.prototype.chooseStatus = function (id, choose) {
        this.sidebarFilterStore.chooseStatus(id, choose);
        this.searchService.search();
    };
    SidebarSupportComponent.prototype.chooseAllOrOneDepartment = function (id, event) {
        if (event === void 0) { event = null; }
        if (event) {
            event.stopPropagation();
        }
        this.sidebarFilterStore.chooseAllOrOneDepartment(id);
        this.searchService.search();
    };
    SidebarSupportComponent.prototype.chooseAllOrOneStatus = function (id, event) {
        if (event === void 0) { event = null; }
        if (event) {
            event.stopPropagation();
        }
        this.sidebarFilterStore.chooseAllOrOneStatus(id);
        this.searchService.search();
    };
    SidebarSupportComponent.prototype.chooseSelfAssigned = function (event) {
        if (event === void 0) { event = null; }
        if (event) {
            event.stopPropagation();
        }
        this.sidebarFilterStore.chooseSelfAssigned();
        this.searchService.search();
    };
    return SidebarSupportComponent;
}());
export { SidebarSupportComponent };
