import { AppSettings } from 'app-settings';
import { LinkUtil } from '../../link-util';
var Message = /** @class */ (function () {
    function Message() {
        this.attachments = [];
    }
    Object.defineProperty(Message.prototype, "authorType", {
        get: function () {
            return this.type;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Message.prototype, "isCustomerMessage", {
        get: function () {
            return ['customer'].indexOf(this.authorType) >= 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Message.prototype, "isAdminMessage", {
        get: function () {
            return ['admin'].indexOf(this.authorType) >= 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Message.prototype, "isNote", {
        get: function () {
            return ['note'].indexOf(this.authorType) >= 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Message.prototype, "isLog", {
        get: function () {
            return ['log'].indexOf(this.authorType) >= 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Message.prototype, "isTranslated", {
        get: function () {
            return this.translated;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Message.prototype, "editableAndDeletable", {
        get: function () {
            return (this.authorType !== 'log') &&
                this.date.getTime() + AppSettings.TIME_FOR_EDIT_MESSAGE > new Date().getTime();
        },
        enumerable: true,
        configurable: true
    });
    Message.prototype.setAttachments = function (atts, panel) {
        if (atts) {
            atts.forEach(function (item) {
                var fileName = LinkUtil.escape(item['fileName']);
                item['url'] = "/ticket/attachment?attachmentId=" + encodeURIComponent(item['id'])
                    + ("&name=" + encodeURIComponent(fileName))
                    + ("&panel=" + encodeURIComponent(panel));
            });
            this.attachments = atts;
        }
    };
    return Message;
}());
export { Message };
